/**
 * 搜索头
 */
export const searchFileds = [
  {
    type: "input",
    field: "card_no",
    label: "卡号",
    iw: 200
  },
  {
    type: "select",
    field: "account_service",
    label: "服务",
    options: [
      { value: "GENERIC", label: "通用" },
      { value: "ETC", label: "ETC" },
      { value: "GAS", label: "油" },
      { value: "GEO", label: "轨迹" },
      { value: "OCR", label: "OCR" },
      { value: "SIGN", label: "电子签约" },
      { value: "SMS", label: "短信" },
      { value: "DATA_CHECK", label: "数据自检" },
      { value: "GPS", label: "车辆位置" },
      { value: "VERIFY", label: "认证" }
    ],
    iw: 200
  },
  {
    type: "select",
    field: "status",
    label: "状态",
    options: [
      // { value: "PENDING", label: "生效中" },
      { value: "ENABLE", label: "已生效" },
      { value: "DISABLE", label: "已失效" }
    ],
    iw: 200
  }
];
