import { Tooltip } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
const getstatus = row => {
  switch (row.status_desc) {
    case "未生效":
      return (
        <span>
          <a-badge status="warning" />
          未生效
        </span>
      );
    case "已生效":
      return (
        <span>
          <a-badge status="success" />
          已生效
        </span>
      );
    case "已失效":
      return (
        <span>
          <a-badge status="error" />
          已失效
        </span>
      );
    case "已作废":
      return (
        <span>
          <a-badge status="error" />
          已作废
          <Tooltip class="ml-8" title={row.ext_json?.remark}>
            <QuestionCircleOutlined style={{ color: "#0076ff" }} />
          </Tooltip>
        </span>
      );
  }
};

//table列配置
export const getColumns = () => {
  const columns = [
    {
      width: 100,
      type: "checkbox",
      field: "$$id",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "卡号",
      dataIndex: "card_no",
      minWidth: 140
    },
    {
      title: "服务范围",
      dataIndex: "account_service_desc",
      minWidth: 120
    },
    {
      title: "面值(元)",
      field: "recharge_amount",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "失效时间",
      field: "valid_end",
      minWidth: 120,
      cellRender: "p-date"
    },
    {
      title: "状态",
      dataIndex: "status_desc",
      minWidth: 120,
      customRender: (_, row) => {
        return getstatus(row);
      }
    },
    {
      title: "操作",
      dataIndex: "action",
      minWidth: 80,
      align: "left",
      fixed: "right"
    }
  ];
  return columns;
};
