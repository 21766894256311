<template>
  <div class="mt-24">
    <!-- 消费卡 -->
    <!-- 搜索头 -->
    <search-header :schemaData="searchFileds" @getDataList="handleFormSearch" />
    <!-- 列表 -->
    <div class="mx-24 mt-12 bg-white">
      <p-table
        rowKey="id"
        :dataSource="dataSource"
        :columns="tableColumns"
      ></p-table>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import { getColumns } from "./constants/table-config";
import { useAccountQueryApi } from "@/apis/account.js";
import useSearchForm from "@/hooks/UseSearchForm";
import { useStore } from "vuex";

export default defineComponent({
  name: "consumer-card",

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const tableColumns = reactive(getColumns());
    const tableDataSource = reactive(getInitTable());
    const { filter, handleFormSearch } = useSearchForm(searchFileds);

    const accountQuery = useAccountQueryApi();
    const isFirst = ref(false);

    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        let params = {};
        const query = route.query?.account_service;
        if (query && query === "CONSUMER") {
          params.account_service_not_in = "DATA_CHECK";
        } else if (query && query === "DATA_CHECK") {
          params.account_service = "DATA_CHECK";
        }
        if (isFirst.value) {
          params = {};
        }
        isFirst.value = true;
        const data = {
          ...filter.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo,
          query_amount_flag: true,
          account_type: "CONSUMER",
          ...params
        };
        const res = await accountQuery(data);
        return { records: res.list, total: res.total };
      }
    });

    // 操作列渲染逻辑
    tableColumns.forEach(item => {
      switch (item.dataIndex) {
        // 审核状态
        case "action":
          item.customRender = (_, row) => {
            return (
              store.getters.roles.includes("SpendingBills") && (
                <div>
                  <a
                    class="color-primary  text-no-wrap"
                    style="color: #1684FB"
                    onClick={() => handleActionClick(row)}
                  >
                    消费账单
                  </a>
                </div>
              )
            );
          };
          break;
      }
    });

    const handleActionClick = row => {
      router.push({
        path: "/financial/spending-bills",
        query: { id: row.id }
      });
    };

    return {
      tableColumns,
      searchFileds,
      handleFormSearch,
      getConsultingList,
      handleActionClick,
      dataSource: tableDataSource
    };
  }
});
</script>
