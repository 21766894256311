import { reactive, ref } from "vue";
import { forEach } from "lodash-es";
import moment from "moment";
import { pageSizeNum } from "@/constants/enum/global";

//默认table配置
export const getInitTable = () => ({
  data: [],
  error: null,
  loading: false,
  meta: {
    total: 0,
    pageSize: pageSizeNum,
    currentPageNo: 1,
    selectedRows: []
  },
  sum: {
    data: [],
    error: null,
    loading: false
  }
});

//页头tab页使用的
export const useHeadTab = tabList => {
  const currentTabRef = ref(tabList[0].key);
  const tabListRef = reactive(tabList);
  const setCurrentTab = current => {
    currentTabRef.value = current;
  };
  return {
    currentTabRef,
    tabListRef,
    setCurrentTab
  };
};

// 搜索列表数据处理
export const useSearchForm = schemaData => {
  // 初始化filter 否则useTableConfig里面监听不到
  const searchForm = {};
  forEach(schemaData, value => {
    if (value.field === "dateRange") {
      searchForm.startTimeLong = null;
      searchForm.endTimeLong = null;
    } else {
      searchForm[value.field] = null;
    }
  });

  const filter = ref(searchForm);

  // 表格查询
  const handleFormSearch = newFilter => {
    forEach(newFilter, (value, key) => {
      if (key === "dateRange") {
        filter.value["startTimeLong"] = value ? value[0] : null;
        filter.value["endTimeLong"] = value ? value[1] : null;
      }
      filter.value = newFilter;
    });
  };
  return {
    filter,
    handleFormSearch
  };
};

export const useForm = ({ formRef }) => {
  const loading = ref(false);
  const updateLoading = isLoading => {
    loading.value = isLoading;
  };

  return {
    loading,
    updateLoading,
    validate: (...params) => formRef.value.validate(...params),
    validateField: (...params) => formRef.value.validateField(...params),
    resetFields: (...params) => formRef.value.resetFields(...params),
    clearValidate: (...params) => formRef.value.clearValidate(...params)
  };
};

export const useEditForm = editFormData => {
  const initData = { ...editFormData, isEdit: false };
  const editDataRef = ref({ ...initData });
  const getInitData = () => ({ ...initData });
  const setEditData = (newData, isEdit = true) => {
    const data = {};
    Object.keys(editFormData).forEach(key => {
      if (key === "expires") {
        let beginTime = newData.idCardBeginTime;
        let endTime = newData.idCardEndTime;
        data[key] = [
          beginTime ? moment(beginTime) : null,
          endTime ? moment(endTime) : null
        ];
        return;
      }
      data[key] = newData[key];
    });
    data.isEdit = isEdit;
    editDataRef.value = data;
    return data;
  };

  return {
    editDataRef,
    getInitData,
    setEditData
  };
};
